import { detect } from "detect-browser";
import { observer } from "mobx-react-lite";
import { isSafari } from "react-device-detect";
import { Server } from "revolt.js/dist/maps/Servers";
import styled from "styled-components";

import { useContext } from "preact/hooks";

import { AppContext } from "../../context/revoltjs/RevoltClient";

import { IconBaseProps, ImageIconBase } from "./IconBase";

interface Props extends IconBaseProps<Server> {
    server_name?: string;
}

const ServerText = styled.div`
    display: grid;
    padding: 0.2em;
    overflow: hidden;
    place-items: center;
    color: var(--foreground);
    background: var(--primary-background);
    border-radius: var(--border-radius-half);
`;

// const fallback = "/assets/group.png";
export default observer(
    (
        props: Props &
            Omit<
                JSX.HTMLAttributes<HTMLImageElement>,
                keyof Props | "children" | "as"
            >,
    ) => {
        const client = useContext(AppContext);

        const { target, attachment, size, animate, server_name, ...imgProps } =
            props;

        const args = () => {
            if (isSafari) {
                const browser = detect();
                if ((browser?.version || "") < "14.0.0") {
                    return {};
                }
            }
            return { max_side: 256 };
        };
        const iconURL = client.generateFileURL(
            target?.icon ?? attachment,
            args(),
            animate,
        );

        if (typeof iconURL === "undefined") {
            const name = target?.name ?? server_name ?? "";

            return (
                <ServerText style={{ width: size, height: size }}>
                    {name
                        .split(" ")
                        .map((x) => x[0])
                        .filter((x) => typeof x !== "undefined")}
                </ServerText>
            );
        }

        return (
            <ImageIconBase
                {...imgProps}
                width={size}
                height={size}
                borderRadius="--border-radius-server-icon"
                src={iconURL}
                loading="lazy"
                aria-hidden="true"
            />
        );
    },
);
